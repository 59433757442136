<template>
  <div class="mt-4 mx-3 row white-box px-3 py-4">
    <div class="white-box-top"></div>
    <div class="col-md-4 col-lg-2">
      <nav class="left-nav">
        <div
          class="items"
          v-scroll-spy-link="{ selector: 'a', class: 'btn-primary' }"
          v-scroll-spy-active="{ selector: 'a div', class: 'btn-primary' }"
        >
          <div class="items">
            <a href="#list"
              ><div class="btn w-100 btn-simple text-start">
                {{ $t("domains.servers") }}
              </div></a
            >
            <a href="#add"
              ><div class="btn w-100 btn-simple text-start">
                {{ $t("domains.addNewServer") }}
              </div></a
            >
          </div>
        </div>
      </nav>
    </div>
    <div class="col-md-8 col-lg-10" v-scroll-spy="{ offset: 120 }">
      <section id="list">
        <h2>
          {{ $t("domains.serverSettings") }}
        </h2>
        <div v-if="domain">
          <div class="row">
            <div class="col">
              <h4>{{ domain.domain.domain }}</h4>
            </div>
            <div class="col-auto">
              <button class="btn btn-link" v-on:click="domain = null">
                back
              </button>
            </div>
          </div>
          <div v-if="addNewRecord" class="mt-3 mb-4">
            <h4>New record</h4>
            <div class="row custom-input whitebg-input mb-3">
              <div class="col-2">
                <label class="form-label bold-12 text-gray">Type</label>
                <v-select
                  :options="recordTypes"
                  v-model="newRecord.type"
                  label="name"
                  :placeholder="$t(`finance.notSelected`)"
                  append-to-body
                />
              </div>
              <div class="col-3">
                <label class="form-label bold-12 text-gray">Host</label>
                <div class="fix-end-input">
                  <input
                    type="text"
                    v-model="newRecord.host"
                    class="form-control"
                  />
                  <span> .{{ domain.domain.domain }} </span>
                </div>
              </div>
              <div class="col-4">
                <label class="form-label bold-12 text-gray">{{
                  ["A", "AAAA"].indexOf(newRecord.type) > -1
                    ? "IP address"
                    : "Value"
                }}</label>
                <input
                  type="text"
                  v-model="newRecord.answer"
                  class="form-control"
                />
              </div>
              <div
                class="col-1"
                v-if="['MX', 'SRV'].indexOf(newRecord.type) > -1"
              >
                <label class="form-label bold-12 text-gray">Priority</label>
                <input
                  type="number"
                  v-model="newRecord.priority"
                  class="form-control"
                />
              </div>
              <div
                :class="
                  ['MX', 'SRV'].indexOf(newRecord.type) > -1 ? 'col-2' : 'col-3'
                "
              >
                <label class="form-label bold-12 text-gray">TTL</label>
                <div class="fix-end-input">
                  <input
                    type="number"
                    v-model="newRecord.ttl"
                    class="form-control"
                  />
                  <span> sec </span>
                </div>
              </div>
            </div>
            <button class="btn btn-secondary" v-on:click="addRecord">
              Add record
            </button>
          </div>
          <OneByOneTable
            v-model:rows="recordRows"
            v-model:items="domain.records"
            type="Record"
            :show-add="true"
            :show-del="true"
            v-on:addbtn="addNewRecord = !addNewRecord"
            v-on:delete="startDeleteRecord"
            v-on:update:field="updateRecord"
          />
        </div>
        <div v-else>
          <h4>
            {{ $t("domains.servers") }}
          </h4>
          <OneByOneTable
            v-model:rows="tokenRows"
            v-model:items="domains"
            type="Domain"
            :show-add="false"
            :show-edit="true"
            :show-del="false"
            v-on:edit="editDomain"
          />
        </div>
      </section>
      <section id="new" v-if="!domain">
        <h4>
          {{ $t("domains.buyNewDominName") }}
        </h4>
        <div v-if="step == 1" class="mt-4 col-12">
          <div class="row text-start custom-input whitebg-input">
            <div class="row mb-3 text-center justify-content-center">
              <div class="col-10 col-lg-6">
                <label class="form-label bold-12 text-gray">
                  {{ $t("domains.domainName") }}
                </label>
                <input
                  type="text"
                  class="form-control remove-padding"
                  v-model="q"
                />
              </div>
            </div>
            <div class="row mb-3 text-center justify-content-center">
              <div class="col-10 col-lg-6 text-center">
                <button
                  class="btn btn-primary"
                  v-on:click="search()"
                  :class="{ 'loading-spin': loadSearchBtn }"
                  :disabled="loadSearchBtn"
                >
                  <span>
                    {{ $t("domains.search") }}
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div
            class="col-12 row mt-2"
            v-for="domain in result"
            :key="domain.domain"
          >
            <div class="col-6 bold-16">
              {{ domain.domain }}
            </div>
            <div class="col-3 text-center" v-if="domain.purchasable">
              <div class="bold-16">
                <EditOnClick
                  :model-value="domain.purchasePrice"
                  type="price"
                  :editable="false"
                  :padding="false"
                />
              </div>
              <div class="regular-12">
                {{ $t("domains.renewal") }}
                <EditOnClick
                  :model-value="domain.renewalPrice"
                  type="price"
                  :editable="false"
                  :padding="false"
                />
              </div>
            </div>
            <div class="col-3 text-gray text-center" v-else>
              {{ $t("domains.notAvailable") }}
            </div>
            <div class="col-3" v-if="domain.purchasable">
              <button class="btn btn-primary" v-on:click="selectDomain(domain)">
                {{ $t("domains.buy") }}
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="step == 2"
          class="px-4 mt-4 col-12 custom-input whitebg-input"
        >
          <h4>Domain settings</h4>
          <div class="row my-3">
            <div class="col-6">
              <label class="form-label bold-12 text-gray"> Owner </label>
              <v-select
                :options="companies"
                v-model="newDomain.company_id"
                label="name"
                :placeholder="$t(`finance.notSelected`)"
                :reduce="(c) => c.id"
                append-to-body
              />
            </div>
            <div class="col-6">
              <label class="form-label bold-12 text-gray"> Duration </label>
              <v-select
                :options="selectedDomain.durations"
                v-model="newDomain.years"
                :placeholder="$t(`finance.notSelected`)"
                append-to-body
              >
                <template v-slot:selected-option="option">
                  {{ option.label }} year
                </template>
                <template v-slot:option="option">
                  {{ option.label }} year
                </template>
              </v-select>
            </div>
          </div>
          <h4>Contact person</h4>
          <div class="row my-3">
            <div class="col-6">
              <label class="form-label bold-12 text-gray"> First Name </label>
              <input
                type="text"
                v-model="newDomain.first_name"
                class="form-control"
              />
            </div>
            <div class="col-6">
              <label class="form-label bold-12 text-gray"> Last Name </label>
              <input
                type="text"
                v-model="newDomain.last_name"
                class="form-control"
              />
            </div>
            <div class="col-6">
              <label class="form-label bold-12 text-gray"> Email </label>
              <input
                type="email"
                v-model="newDomain.email"
                class="form-control"
              />
            </div>
            <div class="col-6">
              <label class="form-label bold-12 text-gray"> Phone </label>
              <input
                type="tel"
                v-model="newDomain.phone"
                class="form-control"
              />
            </div>
          </div>
          <h4>Summary</h4>
          <div class="row">
            <div class="col-6 mb-4 text-end">First year:</div>
            <div class="col-6 mb-4">
              <EditOnClick
                :model-value="selectedDomain.purchasePrice"
                type="price"
                :editable="false"
                :padding="false"
              />
            </div>
            <div class="col-6 mb-4 text-end" v-if="newDomain.years > 1">
              Additional years:
            </div>
            <div class="col-6 mb-4" v-if="newDomain.years > 1">
              <EditOnClick
                :model-value="renewalPrice"
                type="price"
                :editable="false"
                :padding="false"
              />
            </div>
            <div class="col-6 mb-4 regular-24 text-end text-gradient">
              Total:
            </div>
            <div class="col-6 mb-4 bold-24 text-gradient">
              <EditOnClick
                :model-value="fullPrice"
                type="price"
                :editable="false"
                :padding="false"
              />
            </div>
            <div class="col-12 mb-4 text-center">
              <button
                v-on:click="buyDomain()"
                class="btn btn-type-2"
                :class="{ 'loading-spin': loadBuyBtn }"
                :disabled="loadBuyBtn"
              >
                <span>Buy</span>
              </button>
            </div>
          </div>
        </div>
        <hr class="my-4" />
      </section>
      <ConfirmModal
        ref="deleteModal"
        title="Record törlése"
        :text="`<ul><li>You will delete the record: <i>${selectedRecord?.type} ${selectedRecord?.host}</i><b></li><li>You can't undo this action</li></ul><p>Are you sure you want to delete?</p>`"
        cancel-button="No, keep the record"
        ok-button="Yes, delete the record"
        v-on:success="deleteRecord"
      />
    </div>
  </div>
</template>

<script>
import OneByOneTable from "../lists/OneByOneTable";
import http from "@/modules/http";
import { useStore } from "vuex";
import EditOnClick from "../inputs/EditOnClick.vue";
import ConfirmModal from "../modals/ConfirmModal.vue";
export default {
  name: "Domains",
  components: { ConfirmModal, EditOnClick, OneByOneTable },
  data() {
    return {
      store: useStore(),
      step: 1,
      domain: null,
      newDomain: {
        years: 1,
      },
      domains: undefined,
      recordTypes: ["A", "AAAA", "ANAME", "CNAME", "MX", "NS", "SRV", "TXT"],
      newRecord: {},
      addNewRecord: false,
      recordRows: [
        {
          name: "Type",
          key: "type",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Host",
          key: "host",
          type: "string",
          show: true,
          editable: true,
        },
        {
          name: "Value",
          key: "answer",
          type: "string",
          show: true,
          editable: true,
        },
        {
          name: "Priority",
          key: "priority",
          type: "number",
          show: true,
          editable: true,
        },
        {
          name: "TTL",
          key: "ttl",
          type: "number",
          show: true,
          editable: true,
        },
      ],
      tokenRows: [
        {
          name: "Domain",
          key: "domain",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Expire at",
          key: "expire_date",
          type: "date",
          show: true,
          editable: false,
        },
        {
          name: "Autorenew enabled",
          key: "autorenew_enabled",
          type: "boolean",
          show: true,
          editable: false,
          center: true,
        },
        {
          name: "Renewal price",
          key: "renewal_price",
          type: "number",
          show: true,
          editable: false,
        },
      ],
      loadSearchBtn: false,
      loadBuyBtn: false,
      q: "",
      result: [],
      companies: [],
      selectedDomain: {},
      selectedRecord: {},
    };
  },
  computed: {
    webshops() {
      return this.store.state.webshops;
    },
    renewalPrice() {
      return {
        amount:
          this.selectedDomain?.renewalPrice?.amount *
          ((this.newDomain?.years ?? 1) - 1),
        currency: this.selectedDomain?.purchasePrice?.currency,
      };
    },
    fullPrice() {
      return {
        amount:
          this.selectedDomain?.purchasePrice?.amount +
          this.selectedDomain?.renewalPrice?.amount *
            ((this.newDomain?.years ?? 1) - 1),
        currency: this.selectedDomain?.purchasePrice?.currency,
      };
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      http.fetch("/domains").then((data) => {
        this.domains = data.domains;
      });
      if (this.companies.length < 1) {
        http.fetch("/companies").then((data) => {
          this.companies = data;
        });
      }
    },
    search() {
      this.loadSearchBtn = true;
      http.fetch("/domains/search", { q: this.q }).then((data) => {
        this.result = data.domains;
        this.loadSearchBtn = false;
      });
    },
    selectDomain(domain) {
      this.selectedDomain = domain;
      this.step = 2;
    },
    buyDomain() {
      this.loadBuyBtn = true;
      let data = Object.assign({}, this.newDomain);
      data.domain = this.selectedDomain.domain.replace(
        "." + this.selectedDomain.tld,
        ""
      );
      data.tld = this.selectedDomain.tld;
      http
        .fetch("/domains/buy", data)
        .then(() => {
          this.loadBuyBtn = false;
          this.load();
          this.step = 1;
        })
        .catch(() => {
          this.loadBuyBtn = false;
        });
    },
    editDomain(id) {
      http.fetch("/domains/" + id).then((data) => {
        this.domain = data;
      });
    },
    addRecord() {
      http
        .fetch(
          "/domains/" + this.domain.domain.id + "/record",
          this.newRecord,
          true
        )
        .then((data) => {
          this.addNewRecord = false;
          this.domain.records.push(data);
        });
    },
    updateRecord(object) {
      http.fetch(
        "/domains/" + this.domain.domain.id + "/record",
        object,
        true,
        "PUT"
      );
    },
    startDeleteRecord(record) {
      this.selectedRecord = record;
      this.$refs.deleteModal.showModal();
    },
    deleteRecord() {
      http
        .fetch(
          "/domains/" + this.domain.domain.id + "/record",
          { id: this.selectedRecord.id },
          true,
          "DELETE"
        )
        .then(() => {
          let i = this.domain.records.findIndex(
            (e) => e.id === this.selectedRecord.id
          );
          this.selectedRecord = {};
          this.domain.records.splice(i, 1);
        });
    },
  },
};
</script>
